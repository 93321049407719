import React, { useEffect, useRef, useState } from 'react'
import { shared } from '../../assets/images'
import './style/map.css'
import { AlertDialog } from '../../components/AlertDialog'

function MapGoogle({ vehicles, view, selectedVehicleData, userLocation }) {
  const [count, setCount] = useState(0)
  const [googleMarkers, setGoogleMarkers] = useState(null)
  const [vehiclesLenght, setVehiclesLenght] = useState(
    Object.keys(vehicles || {}).length
  )
  const [showAlert, setShowAlert] = useState(false)
  const [alertDismissed, setAlertDismissed] = useState(false)
  const infoWindow = new google.maps.InfoWindow()
  const mapRef = useRef()
  const userMarkerRef = useRef(null)

  // Función para cerrar el modal
  const closeAlert = () => {
    setShowAlert(false)
    setAlertDismissed(true)
  }

  const updateMarkers = (updatedVehicles) => {
    setCount(count + 1)
    Object.keys(updatedVehicles).forEach((key) => {
      const position = {
        lng: updatedVehicles[key].longitude,
        lat: updatedVehicles[key].latitude,
      }
      const marker = googleMarkers[key]
      if (marker) {
        googleMarkers[key].setPosition(position)
        googleMarkers[key].setIcon({
          url: updatedVehicles[key].img,
          scaledSize: new window.google.maps.Size(50, 50),
        })
        // eslint-disable-next-line no-unused-expressions
        key === selectedVehicleData[0]?.Chasis
          ? googleMarkers[key].setAnimation(window.google.maps.Animation.BOUNCE)
          : googleMarkers[key].setAnimation(null)
      }
    })
  }

  const updateUserMarker = () => {
    if (userLocation && userLocation.latitude && userLocation.longitude) {
      const userPosition = {
        lat: userLocation.latitude,
        lng: userLocation.longitude,
      }

      if (!userMarkerRef.current) {
        userMarkerRef.current = new window.google.maps.Marker({
          position: userPosition,
          map: mapRef.current.mapInstance,
          icon: {
            url: shared.userLocation,
            scaledSize: new window.google.maps.Size(40, 40),
          },
        })

        const userInfoWindow = new google.maps.InfoWindow({
          content: `
          <div style="text-align: center; font-family: Arial, sans-serif;">
            <p style="margin: 0; font-size: 16px; padding-top: 10px;">¡Tú estás aquí! 😊</p>
          </div>`,
        })

        userInfoWindow.open({
          anchor: userMarkerRef.current,
          map: mapRef.current.mapInstance,
        })
      } else {
        userMarkerRef.current.setPosition(userPosition)
      }

      if (!alertDismissed) {
        setShowAlert(true)
      }
    }
  }

  useEffect(() => {
    const markers = googleMarkers || {}
    const notEmpty = Object.keys(markers).length > 0
    if (notEmpty) {
      updateMarkers(vehicles)
    }
    setVehiclesLenght(Object.keys(vehicles || {}).length)
  }, [vehicles, selectedVehicleData])

  useEffect(() => {
    const map = new window.google.maps.Map(mapRef.current, {
      zoom: view.zoomLevel,
      center: view.center,
      mapTypeControl: false,
    })

    mapRef.current.mapInstance = map

    const markers = Object.keys(vehicles || {}).reduce(
      (reducedMarkers, vehicleKey) => {
        const vehicle = vehicles[vehicleKey]
        const markerPosition = { lng: vehicle.longitude, lat: vehicle.latitude }
        const vehicleMarked = new window.google.maps.Marker({
          position: markerPosition,
          map,
          icon: {
            url: vehicle.img,
            scaledSize: new window.google.maps.Size(50, 50),
          },
        })
        vehicleMarked.addListener('click', () => {
          infoWindow.setContent(
            "<div className='flex flex-col content-start p-2'>" +
              "<h1 className='font-semibold text-lg mb-2'>" +
              `${vehicle.Marca}` +
              '</h1>' +
              `<p>${vehicle.Modelo}</p>`
          )
          infoWindow.open({
            anchor: vehicleMarked,
            map,
            shouldFocus: true,
          })
          vehicleMarked.setMap(map)
        })

        return { ...reducedMarkers, [vehicleKey]: vehicleMarked }
      },
      {}
    )
    setGoogleMarkers(markers)
  }, [vehiclesLenght, view])

  useEffect(() => {
    updateUserMarker()
  }, [userLocation?.latitude, userLocation?.longitude])

  return (
    <>
      {showAlert && (
        <AlertDialog
          openAlert={showAlert}
          handleCloseAlert={closeAlert}
          handleAceptAlert={closeAlert}
          handleCancelAlert={closeAlert}
          title="Ubicación actualizada"
          subtitle="Si ya activaste la ubicación en tiempo real y no ves tu ubicación, intenta recargar la página. Si aún no puedes verla, asegúrate de activar la opción de ubicación en la configuración de tu navegador. Luego, recarga la página para intentarlo nuevamente."
          mainbuttonTitle="Cerrar"
        />
      )}
      <div ref={mapRef} id="map" style={{ width: '100%', height: '100%' }} />
    </>
  )
}

export { MapGoogle }
