import { useState, useEffect, useRef } from 'react'
import { shared } from '../assets/images'

const useUserLocation = () => {
  // States to manage position data, error messages, and status
  const [position, setPosition] = useState({})
  const [userLocation, setUserLocation] = useState(null)
  const [error, setError] = useState(null)
  const [isWatching, setIsWatching] = useState(false)

  // Refs for previous location, watch ID, retry timeout, etc.
  const prevLocation = useRef(null)
  const watchIdRef = useRef(null)
  const retryTimeoutRef = useRef(null)
  const lastUpdateTime = useRef(0)
  const isMounted = useRef(true)

  // Function to calculate the distance between two coordinates using the Haversine formula
  const calculateDistance = (loc1, loc2) => {
    if (!loc1 || !loc2) return Infinity

    const R = 6371e3 // Earth's radius in meters
    const φ1 = (loc1.latitude * Math.PI) / 180
    const φ2 = (loc2.latitude * Math.PI) / 180
    const Δφ = ((loc2.latitude - loc1.latitude) * Math.PI) / 180
    const Δλ = ((loc2.longitude - loc1.longitude) * Math.PI) / 180

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    return R * c
  }

  // Function to calculate speed in km/h given two locations and a time difference
  const calculateSpeed = (loc1, loc2, timeDiff) => {
    if (!loc1 || !loc2 || !timeDiff) return 0
    const distance = calculateDistance(loc1, loc2)
    const speedMps = distance / (timeDiff / 1000) // Speed in meters per second
    return Math.round(speedMps * 3.6) // Convert to km/h
  }

  // Handle updates to the user's position
  const handlePosition = (position) => {
    if (!isMounted.current) return

    const currentTime = Date.now()
    const timeDiff = currentTime - lastUpdateTime.current

    // Update every 500ms for smoother movement tracking
    if (timeDiff < 500) return

    const newLocation = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      accuracy: position.coords.accuracy,
      timestamp: position.timestamp,
    }

    // Prepare the updated position format
    const newPositionFormat = {
      img: shared.motorNO,
      velocity: prevLocation.current
        ? calculateSpeed(prevLocation.current, newLocation, timeDiff).toString()
        : '0',
      turnOn: '1',
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      meters: position.coords.accuracy.toString(),
      onLine: '1',
      chasis: 'user',
      angle: position.coords.heading?.toString() || '0',
      time: position.timestamp,
      accuracy: position.coords.accuracy,
    }

    // Update state if distance exceeds 1 meter (increased sensitivity)
    const distance = calculateDistance(prevLocation.current, newLocation)
    if (!prevLocation.current || distance > 1) {
      if (isMounted.current) {
        // Update both userLocation and position states
        setUserLocation(newLocation)
        setPosition((prev) => ({
          ...prev,
          user: newPositionFormat,
        }))
      }

      prevLocation.current = newLocation
      lastUpdateTime.current = currentTime
      if (isMounted.current) {
        setError(null)
      }
    }
  }

  // Handle geolocation errors and manage retries
  const handleError = (err) => {
    if (!isMounted.current) return

    let mensaje = 'Error al obtener la ubicación'
    switch (err.code) {
      case err.PERMISSION_DENIED:
        mensaje = 'Usuario denegó el acceso a la ubicación'
        setIsWatching(false)
        break
      case err.POSITION_UNAVAILABLE:
        mensaje = 'Información de ubicación no disponible'
        retryTimeoutRef.current = setTimeout(startWatching, 2000)
        break
      case err.TIMEOUT:
        mensaje = 'Se agotó el tiempo para obtener la ubicación'
        retryTimeoutRef.current = setTimeout(startWatching, 1000)
        break
      default:
        retryTimeoutRef.current = setTimeout(startWatching, 1000)
    }
    console.error('Error de ubicación:', mensaje)
    if (isMounted.current) {
      setError(mensaje)
    }
  }

  // Start watching for location updates
  const startWatching = () => {
    if (!isMounted.current) return

    if (!navigator.geolocation) {
      setError('Tu navegador no soporta geolocalización')
      return
    }

    if (watchIdRef.current) {
      navigator.geolocation.clearWatch(watchIdRef.current)
    }

    try {
      // Get initial position immediately
      navigator.geolocation.getCurrentPosition(handlePosition, handleError, {
        enableHighAccuracy: true,
        timeout: 2000,
        maximumAge: 0,
      })

      // Set up aggressive watching configuration
      watchIdRef.current = navigator.geolocation.watchPosition(
        handlePosition,
        handleError,
        {
          enableHighAccuracy: true,
          timeout: 2000,
          maximumAge: 0,
        }
      )

      if (isMounted.current) {
        setIsWatching(true)
      }
    } catch (e) {
      console.error('Error al iniciar el seguimiento:', e)
      if (isMounted.current) {
        setError('Error al iniciar el seguimiento de ubicación')
      }
    }
  }

  // Effect to handle component lifecycle and cleanup
  useEffect(() => {
    isMounted.current = true
    lastUpdateTime.current = Date.now()
    startWatching()

    return () => {
      isMounted.current = false
      if (watchIdRef.current) {
        navigator.geolocation.clearWatch(watchIdRef.current)
      }
      if (retryTimeoutRef.current) {
        clearTimeout(retryTimeoutRef.current)
      }
    }
  }, [])

  return {
    position,
    userLocation,
    error,
    isWatching,
    startWatching,
  }
}

export default useUserLocation
